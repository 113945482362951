<template>
  <div>
    <!-- Filter Section -->
    <FilterReportNoDoc title="Filters" :baseReportUrl="baseReportUrl" @clicked-filter="getReport" />
    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

        <b-card-body class="text-center">
          <b-card-title>{{ companyName }}</b-card-title>
          <b-card-sub-title>
            <div>{{ $t('apps.reportJournal.moduleName') }}</div>
            <div class="mt-sm-1">{{ periodReportText }}</div>
            <div v-if="officeReportText" class="mt-sm-1">{{ officeReportText }}</div>
          </b-card-sub-title>
        </b-card-body>

        <b-table
          id="refListTable"
          ref="refListTable"
          no-provider-paging
          no-provider-filtering
          :filter-included-fields="columnToBeFilter"
          :fields="tableColumns"
          :items="itemLists"
          :filter="searchQuery"
          responsive
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :busy="isBusy"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >

          <template #table-busy>
            <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
              <div class="mb-1 text">
                <b-spinner class="align-middle"/>
              </div>
              <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
            </div>
          </template>

        <!-- no  -->
        <template #cell(no)="{ item }">
          <span class="d-block text-nowrap">
              {{ item.no }}
          </span>
        </template>

        <!-- posting_date  -->
        <template #cell(posting_date)="{ item }">
          <span class="d-block text-nowrap">
              {{ item.posting_date }}
          </span>
        </template>

        <!-- posting_date  -->
        <template #cell(document_no)="{ item }">
            <span class="d-block text-nowrap">
              {{ item.document_no }}
            </span>
        </template>

        <!-- nomor jurnal_no  -->
        <template #cell(journal_no)="{ item }">
         <div v-if="item.is_closing === false">
            <b-link
                 :to="{ name: 'apps-transactions-journal-detail', params: { id: item.id_number }}"
                 class="font-weight-bolder"
               >
             <span class="d-block text-nowrap text-left">
               {{ item.journal_no }}
             </span>
            </b-link>
          </div>
          <div v-else>
            <span class="d-block text-nowrap text-left">
               {{ item.journal_no }}
             </span>
            <b-badge pill variant="light-danger">Closed</b-badge>
          </div>
        </template>

        <!-- nomor coa  -->
        <template #cell(coa)="{ item }">
          <span class="d-block text-nowrap">
            {{ item.coa }}
          </span>
        </template>
        
        <!-- nomor remak  -->
        <template #cell(remark)="{ item }">
          <span class="d-block text-nowrap">
            {{ item.remark }}
          </span>
        </template>

        <!-- nomor debit  -->
        <template #cell(debit)="{ item }">
          <span class="d-block text-nowrap text-right">
            {{ item.debit }}
          </span>
        </template>

        <!-- nomor credit  -->
        <template #cell(credit)="{ item }">
          <span class="d-block text-nowrap text-right">
            {{ item.credit }}
          </span>
        </template>

        <!-- user  -->
        <template #cell(users)="{ item }">
            <span class="d-block text-nowrap">
              {{ item.users }}
            </span>
        </template>

        </b-table>

    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItemButton,
  BTable,
  BLink,
  BSpinner,
  BBadge
} from 'bootstrap-vue'
import FilterReportNoDoc from '@/components/reports/FilterReportNoDoc.vue'

import useListTable from '@/comp-functions/useListTable'
import useFilterReport from '@/comp-functions/useFilterReport'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BTable,
    BLink,
    BSpinner,
    FilterReportNoDoc,
    BBadge
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    // export format
    const formats = ['xlsx', 'csv', 'txt']

    // Table Handlers
    const tableColumns = [
      { key: 'no', label: 'No' },
      { key: 'posting_date', label: 'Posting Date' },
      { key: 'document_no', label: 'Document No' },
      { key: 'journal_no', label: 'Journal No' },
      { key: 'coa', label: 'COA' },
      { key: 'remark', label: 'Remark' },
      { key: 'debit', label: 'Debit', thClass: 'text-right', tdClass: 'text-right'  },
      { key: 'credit', label: 'Credit', thClass: 'text-right', tdClass: 'text-right'  },
      { key: 'users', label: 'User' }
    ]

    const columnToBeFilter = [
      'document_no'
    ]

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    return {
      formats,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      columnToBeFilter,
      isSortDirDesc,
      refListTable,
      statusFilter,
      ...useFilterReport({
        baseUrl: 'reports/journal',
        fetchLists
      })
    } 
  },
  methods: {
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        // const title = [this.$store.state.user.currentCompany.name, this.$t('apps.reportLedger.singular.ledgerReport'), this.periodReportText]
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Journal Report', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Journal Report ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
